<template>
  <!-- <div class="home-product" ref="target">
    <HomePanel :title="cate.name" v-for="cate in list" :key="cate.id">
      <template v-slot:right>
        <div class="sub">
          <RouterLink v-for="sub in cate.childList" :key="sub.id" :to="`/category/sub/${sub.id}`">{{sub.name}}</RouterLink>
        </div>
        <XtxMore :path="`/category/${cate.id}`" />
      </template>
      <div class="box">
        <RouterLink class="cover" :to="`/category/${cate.id}`">
          <img v-lazy="cate.picture" alt="">
          <strong class="label">
            <span>{{cate.name}}馆</span>
            <span>{{cate.saleInfo}}</span>
          </strong>
        </RouterLink>
        <ul class="goods-list">
          <li v-for="item in cate.goods" :key="item.id">
            <HomeGoods :goods="item" />
          </li>
        </ul>
      </div>
    </HomePanel>
  </div> -->
  <div class="home-product">
   <div v-for="item in list" :key="item.id">
    <HomePanel :title="item.categoryName" style="background-color: #f5f5f5;"
    :sty="{border: '3px solid red',width: '170px',position: 'absolute',left: 0,top: '90px'}">
      <template #right><XtxMore :path="`/category/${item.categoryId}/true`" /></template>
      <div style="position: relative;height: 426px;">
        <!-- 面板内容 -->
        <Transition name="fade">
          <ul  class="goods-list">
            <li v-for="v in item.productList" :key="v.id" @click="gotopage(`/product/${v.productId}`)">
              <RouterLink :to="`/product/${v.productId}`">
                <img :src="v.image" alt="">
                <p class="name ellipsis">{{v.name}}</p>
                <p class="price">&yen;{{v.price}}</p>
              </RouterLink>
            </li>
          </ul>
          <!-- <HomeSkeleton bg="#f0f9f4" v-else /> -->
        </Transition>
      </div>
    </HomePanel>
   </div>


  </div>
</template>

<script>
import HomePanel from './home-panel'
import HomeGoods from './home-goods'
import HomeSkeleton from './home-skeleton'
import { gotopage, useLazyData } from '@/hooks'
import { findGoods, getHomeShowList } from '@/api/home'
import { ref } from 'vue'
export default {
  name: 'HomeProduct',
  components: { HomePanel, HomeGoods,HomeSkeleton},
  setup () {
    // const { target, result } = useLazyData(findGoods)

    const list = ref([])
    getHomeShowList().then(res =>{
      console.log('list--->',res.data);
      list.value = res.data.splice(0,1)
    })

    return {list,gotopage }
  }
}
</script>

<style scoped lang='less'>
.home-product {
  background: #f5f5f5;
  margin-top: 20px;
  .goods-list {
    display: flex;
    justify-content: space-between;
    height: 406px;
    li {
      width: 306px;
      height: 406px;
      background: #fff;
      .hoverShadow();
      img {
        width: 306px;
        height: 306px;
      }
      p {
        font-size: 22px;
        padding: 12px 30px 0 30px;
        text-align: center;
      }
      .price {
        color: @priceColor;
      }
    }
  }
}
</style>
